// 地圖版本
.footer-map{
    min-height: 960px;
    @extend %relative;

    .footer-bg{
        width: 100%;
        height: 960px;
        @include bgi($position:43%, $size:cover);
    }

    .footer-content{
        margin-bottom: 30px;
        padding: 200px 30px 25px;
        max-width: 1600px;
        @include flex;
        @extend %relative, %mar-auto, %border-box;

        .iframe-content{
            width: 72%;
            height: 660px;

            iframe{
                width: 100%;
                height: 100%;
            }
        }

        .footer-wrapper{
            padding: 0 3%;
            width: 28%;
            @extend %border-box;

            h3{
                margin-bottom: 55px;
                font-family: $noto-serif;
            }

            ul li{
                color: $title-black;
            }

            ul li+li{
                margin-top: 10px;
            }

            button{
                border: 0;
                color: $k-cream;
                background-color: unset;
                @include absolute($right:30px, $bottom:25px);
                @extend %relative, %pointer;
                
                span{
                    margin-left: 8px;
                    width: 1px;
                    height: 50px;
                    background-color: $k-cream;
                    @include transition($s:0.3s);
                    @extend %dis-in-bl;
                    
                    &::after{
                        content: '';
                        width: 1px;
                        height: 45px;
                        background-color: $k-cream;
                        transform: rotate(60deg);
                        transform-origin: top;
                        @include absolute($top:0, $right:5px);
                    }
                }

                &:hover{
                    span{
                        height: 75px;
                    }
                }
            }
        }
    }

    p{
        padding: 0 30px 25px;
        width: 100%;
        color: white;
        @extend %text-a-c, %relative, %border-box;
    }
    
    a{
        color: white;
    }

    @include pad{
        .footer-content{
            padding: 0;
            @include flex($d:column-reverse);

            .iframe-content{
                width: 100%;
                height: 485px;
            }

            .footer-wrapper{
                margin-bottom: 20px;
                padding: 0 30px;
                width: 100%;

                h3{
                    margin-bottom: 25px;
                }

                button{
                    @include absolute($right:30px, $bottom:-70px);
                }
            }
        }

        p{
            width: calc(100% - 80px);
            text-align: left;
        }
    }
}

#index footer .footer-bg{
    background-image: url('../img/footerMap1_bg.png');
    @include absolute($bottom:0);
}

#contact footer .footer-bg{
    background-image: url('../img/footerMap2_bg.png');
    @include absolute($bottom:0);
}

//基本版
.footer-basis{
    min-height: 285px;
    @include bgi($position:right bottom, $size:cover){
        background-image: url('../img/footer_basis_bg.png');
    };
    @include flex($d:column,$j-c:flex-end);
    @extend %relative;

    .footer-content{
        width: 100%;
        max-width: 1600px;
        margin-top: 0;
        margin-bottom: 30px;
        padding: 0 30px;
        @extend %relative, %mar-auto, %border-box;

        ul{
            border-bottom: 1px solid $k-brown;
            padding-bottom: 5px;
            width: calc(100% - 140px);
            max-width: 1100px;
            color: white;
            @include flex($w:wrap, $j-c:space-between);
            @extend %mar-auto, %border-box;
    
            li{
                width: fit-content;
                margin: 0 5px 10px;
            }
    
        }
    
        button{
            border: 0;
            color: $k-cream;
            background-color: unset;
            @include absolute($right:30px, $bottom:0);
            @extend %relative, %pointer;
            
            span{
                margin-left: 8px;
                width: 1px;
                height: 50px;
                background-color: $k-cream;
                @include transition($s:0.3s);
                @extend %dis-in-bl;
                
                &::after{
                    content: '';
                    width: 1px;
                    height: 45px;
                    background-color: $k-cream;
                    transform: rotate(60deg);
                    transform-origin: top;
                    @include absolute($top:0, $right:5px);
                }
            }
    
            &:hover{
                span{
                    height: 75px;
                }
            }
        }
    }

    p{
        padding: 0 30px 25px;
        width: 100%;
        color: white;
        @extend %text-a-c, %relative, %border-box;
    }
    
    a{
        color: white;
    }

    @include mini-pad{
        .footer-content{
            ul{
                width: 100%;
                flex-direction: column;
            }

            button{
                bottom: 3em;
            }
        }
    }
}