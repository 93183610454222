.our-team-content{
    margin-top: 80px;
    margin-bottom: 130px;
    
    @include pad{
        margin-top: 160px;
    }
    
    @include mini-pad{
        margin-top: 130px;
    }

    .our-team-title{
        margin-bottom: 120px;
        @include flex;
        @extend %relative;

        h2{
            padding-top: 160px;
            width: 33%;
            @extend %border-box, %relative;

            @include mini-pad{
                width: 20%;
            }
        }

        .team-title-content{
            width: 67%;
            @extend %border-box, %relative;
    
            @include mini-pad{
                width: 80%;
            }

            >img{
                margin-bottom: 40px;
                width: 100%;
            }

            h3{
                margin-bottom: 25px;
                color: $k-brown;
            }

            ul{
                padding-right: 12.5%;
                @extend %border-box;

                li{
                    margin-bottom: 16px;
                    border-bottom: 1px solid $form-tip;
                    color: $title-black;
                    @extend %pointer;
    
                    h4{
                        @include flex($j-c:space-between);

                        span{
                            width: 70px;
                            height: 50px;
                            background-color: rgba(63, 54, 50, 0.9);

                            &::after{
                                content: '';
                                width: 70px;
                                height: 50px;
                                transform: scaleY(-1);
                                @include transition($s:0.3s);
                                @include bgi($position:center center){
                                    background-image: url('../img/arrow_toggle.svg');
                                };
                                @extend %dis-bl;
    
                                @include mini-pad{
                                    width: 60px;
                                    height: 40px;
                                }
                            }
                        }   
                    }

                    h4.toggle{
                        span{
                            &::after{
                                transform: scaleY(1);
                            }
                        }
                    }
    
                    p{
                        padding: 25px 0 30px;
                        @extend %dis-none;
                    }
                }
            }
        }
    }

    .our-team-photo{
        padding: 0 8.375% 0 33%;
        @extend %border-box;

        @include pad{
            padding: 0 20px;
        }

        h3{
            margin-bottom: 30px;
            color: $k-brown;
        }

        ul{
            @include flex;
            overflow: hidden;
            @extend %relative;

            li{
                min-width: 100%;
                @include transition($s:0.3s);

                img{
                    width: 100%;
                    @extend %dis-bl;
                }
            }

            .pre-btn, .next-btn{
                width: 85px;
                height: 54px;
                background: $arrow-bg;
                @include flex($j-c:center, $a-i:center);
                @extend %pointer;

                &::after{
                    content: url('../img/arrow.svg');
                    @extend %dis-bl;
                }
            }

            .pre-btn{
                @include absolute($bottom:0, $left:0);
            }

            .pre-btn.no-show{
                background: rgba(29, 29, 29, 0.9);

                &::after{
                    opacity: 0.2;
                }
            }

            .next-btn{
                @include absolute($bottom:0, $left:86px);

                &::after{
                    transform: rotate(180deg);
                }
            }

            .next-btn.no-show{
                background: rgba(29, 29, 29, 0.9);

                &::after{
                    opacity: 0.2;
                }
            }
        }
    }
}