.relative-link-content{
    margin-top: 240px;
    margin-bottom: 100px;
    @include flex($j-c:space-between);

    @include pad{
        margin-top: 160px;
        margin-bottom: 20px;
        padding: 0 20px;
    }

    @include mini-pad{
        margin-top: 130px;
    }

    h2{
        width: 33%;
        
        @include mini-pad{
            width: 20%;
        }
    }
    
    .relative-link-wrapper{
        width: 67%;
        padding-right: 12.5%;
        @extend %border-box, %relative;
        
        @include pad{
            padding-right: 0;
        }

        @include mini-pad{
            width: 80%;
        }

        .logo-content{
            margin-bottom: 80px;

            h3{
                margin-bottom: 25px;
                border-bottom: 1px solid $k-brown;
                padding-bottom: 15px;
                color: $k-brown;
                @include flex($a-i:center);

                &::before{
                    content: '';
                    margin-right: 10px;
                    width: 16px;
                    height: 16px;
                    @include bgi($size:contain){
                        background-image: url('../img/relative_link_dec.svg');
                    }
                    display: block;
                }
            }

            .logo-wrapper{
                @include basic_grid($grid-num1:4, $grid-num2:1fr, $grid-r-gap:40px, $grid-c-gap:20px);
                
                @include pad{
                    @include basic_grid($grid-num1:3, $grid-num2:1fr, $grid-r-gap:40px, $grid-c-gap:20px);
                }

                @include mini-pad{
                    @include basic_grid($grid-num1:2, $grid-num2:1fr, $grid-r-gap:40px, $grid-c-gap:20px);
                }

                li{
                    width: 100%;
                    height: 85px;
                    @include flex($j-c:center, $a-i:center);

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}