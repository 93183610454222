.article-list-content{
    margin-top: 240px;
    margin-bottom: 180px;
    @include flex($j-c:space-between);

    @include pad{
        margin-top: 160px;
        margin-bottom: 100px;
        padding: 0 20px;
        @include flex($d:column);
    }

    @include mini-pad{
        margin-top: 130px;
    }

    h2{
        width: 33%;
        @include bgi($position:left top){
            background-image: url('../img/index_article_bg.png');
        };
        
        @include pad{
            margin-bottom: 50px;
            width: 100%;
            background-image: unset;
            writing-mode: unset;
            letter-spacing: 0.05em;
            color: $text-black;
            display: unset;

            &::after{
                margin-left: 20px;
                content: url('../img/title_dec_line_row.svg');
            }
        }
    }

    h2.no-bg{
        background-image: unset;
    }

    .article-list-wrapper{
        width: 67%;
        padding-right: 12.5%;
        @extend %border-box, %relative;
        
        @include pad{
            width: 100%;
            padding-right: unset;
        }
        
        .article-cards{
            width: 100%;
            height: 960px;
            padding-top: 50px;
            @include basic_grid($grid-num1:2, $grid-num2:1fr, $grid-r-gap:100px, $grid-c-gap:80px);
            overflow: auto;
            @extend %border-box;
            
            @include pad{
                @include basic_grid($grid-num1:2, $grid-num2:1fr, $grid-r-gap:60px, $grid-c-gap:50px);
            }

            @include mini-pad{
                @include basic_grid($grid-num1:1, $grid-num2:1fr, $grid-r-gap:60px, $grid-c-gap:50px);
            }
            
            &::-webkit-scrollbar {
                @extend %dis-none;
            }
            
            .article-cards-toplinear{
                width: 100%;
                height: 50px;
                background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
                @include absolute($top:74px, $left:0, $z-i:1);
            }
            
            .article-cards-loadinglinear{
                width: 100%;
                height: 50px;
                background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
                @include absolute($bottom:0px, $left:0, $z-i:1);
            }

            .article-card{
                .card-text-content{
                    flex-direction: column;
                    padding-right: unset;

                    h3{
                        margin-bottom: 12px;
                        min-width: 100%;
                    }
                }
            }
        }
    }
}